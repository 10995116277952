<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  <div id="maincontent_container">
    <h4>New Password <span style="color:red">*</span></h4>
    <span style="color:red">Note: Password must be at least 6 letters or numbers long.</span>
    <spinner v-if="loading" />

    <div v-if="!loading">
      <div v-if="error">
        <div class="alert alert-danger" role="alert">
          {{ error }}
          <button 
            style="float:right"
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="col-3 mb-1">
          <input
            type="password"
            v-model="passcode"
            class="form-control"
            required
            placeholder="New Password"
          />
        </div>

        <br /><br />

        <div class="col-3 mb-1">
          <h4>Retype New Password<span style="color:red">*</span></h4>
          <input
            type="password"
            v-model="passcode2"
            class="form-control"
            required
            placeholder="Retype New Password"
          />
        </div>
        <br />

        <button class="btn btn-primary">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getAPI } from "../../utils/axios-api";
import Spinner from "../../components/Spinner.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";

export default {
  name: "ResetPasswordAction",
  components: { Spinner, BreadcrumbPage },
  props: ["query"],
  setup(props) {
    const router = useRouter();
    const uid = router.currentRoute.value.params.uid;
    const token = router.currentRoute.value.params.token;
    const passcode = ref("");
    const passcode2 = ref("");
    // const message = ref(null);
    const error = ref(null);
    const loading = ref(false);
    const resetSucceeded = ref(false);

    //breadcrumb
    const breadcrumb_links = ref([]);
    breadcrumb_links.value.push({ text: "Home", link: "Home" });
    breadcrumb_links.value.push({
      text: "Reset Password",
      link: "ResetPasswordLink",
    });
    breadcrumb_links.value.push({ text: "New Password", link: "" });
    console

    const handleSubmit = async () => {
      loading.value = true;
      if(passcode.value === passcode2.value){
        var url = "/account/password-reset-complete/";
        getAPI
          .post(url, { uidb64: uid, token: token, password: passcode.value })
          .then((response) => {
            if (response.status === 200) {
              resetSucceeded.value = true;
              // message.value = response.data.Success;
              router.push({
                name: "Login",
                query: { resetMessage: resetSucceeded },
              });
              loading.value = false;
            }
          })
          .catch((err) => {
            console.log(err.response);
            error.value = err.response.data;
            // message.value = "";
            loading.value = false;
          });
      }
      else{
        error.value = "Passwords do NOT match! Please try again!"
        loading.value = false;
        // console.log(error.value,passcode.value,passcode2.value)
      }
      
    };

    return { loading, passcode,passcode2, handleSubmit, error, breadcrumb_links };
  },
};
</script>

<style></style>
